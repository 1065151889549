@font-face{
    font-family: 'Quicksand';
    src: local('Quicksand'), url(../../public/fonts/Quicksand-VariableFont_wght.ttf) format('truetype');
  }

  *{
    font-family: 'Quicksand';
}

header{
    width: 100%;
    display: flex;
    justify-content: center;
}

.logo{
    width: 25%;
}

.text{
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1, h2{
    font-weight: 400;
}

.network{
    margin: 50px 0px;
    font-weight: 400;
}

.networklogo{
    max-width: 75px;
}

.bigcartel, .etsy, .instagram{
    width: 33vw;
    margin-top: 10px;
    margin-left: 50px;
    display: flex;
    align-items: center;
}

.bigcartel>a, .etsy>a, .instagram>a{
    margin-right: 10px;
}

.bigcartel>p, .etsy>p, .instagram>p{
    margin-left: 10px;
}

@media (max-width: 400px){

    .network{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .bigcartel, .etsy, .instagram{
        width: 66vw;
        margin-top: 0px;
        margin-left: 0px;
        display: flex;
        justify-content: space-evenly;
    }

    .bigcartel>a, .etsy>a, .instagram>a{
        margin-right: 10px;
    }
    
    .bigcartel>p, .etsy>p, .instagram>p{
        margin-left: 10px;
    }

}